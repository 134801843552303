@import 'src/styles/utilities/mixins';

.sectionContent {
  padding: 45px;
  flex: 1;
  background-color: var(--color-primary-white);
  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.08));

  @include tablet-min() {
    padding: 30px 20px;
  }

  .orderedList {
    padding-left: 50px;
    list-style-type: decimal;
    li {
      p {
        padding-left: 10px;
      }
    }

    @include tablet-min() {
      padding-left: 20px;
    }
  }

  .description {
    line-height: 24px;
    padding: 15px 0;
    font-weight: 400;

    @include tablet-min() {
      padding: 10px 0 0 0;
    }
  }

  .emailLink {
    text-decoration: underline;
  }
}
