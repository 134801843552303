@import 'src/styles/utilities/mixins';
.button {
  color: var(--color-primary-white);
  background-color: var(--color-primary-yellow);
  padding: 16px 32px;
  font-weight: 700;
  border-radius: 8px;
  width: max-content;
  height: max-content;
  line-height: normal;
  min-width: 170px;

  @include tablet-min() {
    padding: 16px 50px;
    min-width: auto;
  }

  &:hover {
    filter: drop-shadow(0px 2px 20px rgba(255, 163, 37, 0.3));
  }

  &.smaller {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.footer {
    padding: 11px 20px;
  }

  &.icon {
    min-width: unset;
    background-color: var(--color-primary-white);
    border: 1px solid var(--color-secondary-dark-gray);
    padding: 10px;

    &:hover {
      filter: none;
    }
  }

  &.red {
    background-color: var(--color-secondary-accent);

    &:hover {
      filter: drop-shadow(0px 2px 20px rgba(196, 33, 74, 0.3));
    }
  }

  &.withPlus {
    @include tablet-min() {
      padding: 15px 25px;
      min-width: auto;
    }

    &:before {
      content: url('../../../assets/images/icons/plus.svg');
      width: 12px;
      height: 12px;
      margin-right: 6px;
      font-weight: normal;
    }
  }

  &.link {
    padding: 0;
    background-color: transparent;
    color: var(--color-secondary-accent);
    display: flex;

    &:after {
      content: url('../../../assets/images/icons/arrow.svg');
      margin-left: 10px;
      padding-top: 2px;
    }
  }

  &.auth {
    margin-bottom: 16px;
    margin-top: 24px;
  }

  &.linkBtn {
    background-color: white;
    border-radius: 0;
    margin-right: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-primary-black);
    line-height: 16px;
    border-bottom: 1px solid var(--color-primary-black);
    cursor: pointer;
    min-width: auto;

    &:hover {
      filter: none;
    }
  }

  &.btnNA {
    padding: 2px 10px;
    background-color: var(--color-secondary-light-gray);
    border-radius: 4px;
    color: var(--color-secondary-dark-gray);
    font-size: 14px;
    line-height: 21px;
    min-width: auto;

    &.active {
      color: var(--color-primary-black);
      background-color: var(--color-primary-yellow);
    }

    &:hover {
      filter: none;
    }
  }

  &.white {
    border: 1px solid var(--color-secondary-dark-gray);
    color: var(--color-secondary-dark-gray);
    background-color: var(--color-primary-white);
    padding: 11px 18px;
    min-width: auto;

    &:hover {
      filter: none;
    }
  }

  &.hideBtn {
    font-weight: 700;
    font-size: 18px;
    background: transparent;
    padding: 0;
    align-items: center;
    display: none;

    &:after {
      content: url('../../../assets/images/icons/icon-white-arrow-down.svg');
      margin-left: 8px;
    }

    &.opened {
      &:after {
        transform: rotate(180deg);
      }
    }

    @include tablet-min() {
      display: flex;
    }
  }

  &.filledRed {
    background-color: var(--color-secondary-accent);
    padding: 12px 32px;
    min-width: auto;

    &:hover {
      filter: drop-shadow(0px 2px 20px rgba(196, 33, 74, 0.3));
    }
  }

  &.borderedRed {
    background-color: var(--color-primary-white);
    color: var(--color-secondary-accent);
    border: 1px solid var(--color-secondary-accent);
    padding: 12px 20px;
    min-width: unset;

    &:hover {
      filter: drop-shadow(0px 2px 20px rgba(196, 33, 74, 0.3));
    }
  }

  &.borderedYellow {
    background-color: var(--color-primary-white);
    color: var(--color-primary-yellow);
    border: 1px solid var(--color-primary-yellow);
    padding: 12px 20px;
    min-width: unset;

    &:hover {
      filter: drop-shadow(0px 2px 20px rgba(255, 163, 37, 0.3));
    }
  }

  &.hardGray {
    border-radius: unset;
    background-color: var(--color-secondary-light-gray);
    color: var(--color-primary-black);
    padding: 15px 50px;
    font-weight: 600;

    &:hover {
      filter: unset;
    }
  }

  &.editBtn {
    border: none;
    display: flex;
    align-items: center;
    border-radius: unset;
    color: var(--color-primary-yellow);
    text-decoration: underline;
    font-size: 17px;
    font-weight: 700;
    min-width: unset;
    background: unset;
    padding: 0 20px;

    &:before {
      content: url("../../../assets/images/icons/pencil.svg");
      width: 20px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
