.job-placement-container {
  max-width: 1140px;
  margin: 0 auto;
  margin-bottom: 120px;

  &.no-margin {
    margin: 0 !important;
  }

  .ls-multi-form {
    box-shadow: 0px 0px 10px #e7e7e7;
    padding: 40px 60px;
    border-radius: 16px;

    input[type='radio'] {
      width: 24px;
      height: 24px;
      border: 1px solid var(--color-secondary-light-gray);
      box-sizing: border-box;
      border-radius: 100px;
    }

    .action-button {
      text-align: center;
      margin-bottom: 16px;
    }

    .ls-form-heading {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #171717;
      margin-bottom: 20px;
    }
    .ls-form-subheading {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #171717;
      margin-bottom: 40px;
    }

    .login-form {
      max-width: 600px;
      margin: 0 auto;
      .color-fade {
        color: #979797;
      }
      .social-login {
        text-align: center;
        margin-bottom: 30px;
        a {
          margin-left: 6px;
          margin-right: 6px;
        }
      }
      .form {
        max-width: 430px;
        margin: 0 auto;
        .forgot-password {
          text-align: right;
          margin-bottom: 25px;
        }
      }
      .login-footer {
        text-align: center;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #838383;
          margin-right: 8px;
        }
      }
    }
    .check-item-field {
      margin-bottom: 20px;

      .input-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #171717;
        margin-bottom: 30px;
      }
      .input-subheading {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #838383;
        margin-top: -25px;
        margin-bottom: 25px;
      }
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 20px;
        @media screen and (max-width: 991px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: 767px) {
          grid-template-columns: 1fr;
        }
      }
      li {
        display: inline-block;
        margin-bottom: 12px;

        input {
          cursor: pointer;
        }
        .input-label {
          position: relative;
          bottom: 6px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #171717;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }

    .multi-select {
      ul {
        flex-wrap: wrap;
        display: block;
        li {
          margin-right: 0;
          width: auto;
          margin-bottom: 0;

          input {
            position: absolute;
            opacity: 0;
          }
          .input-label {
            display: inline-block;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding: 11px 30px;
            border: 2px solid #838383;
            box-sizing: border-box;
            border-radius: 8px;
            margin: 0 15px 15px 0;
          }
          input:checked + .input-label {
            border-color: var(--color-primary-yellow);
          }
        }
      }
    }

    .notification {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      ul {
        display: block;
        margin-top: 0;
        li {
          margin-right: 0;
          display: block;
          width: auto;
        }
      }
    }

    .final-step {
      margin-bottom: 20px;
      ul {
        display: block;
        margin-bottom: 40px;
        li {
          margin-right: 0;
          display: block;
          width: auto;
        }
      }
      form {
        max-width: 450px;
        .form-group {
          margin-bottom: 40px;
          .input-heading {
            margin-bottom: 15px;
          }
          .input-field {
            input,
            textarea {
              border: 1px solid;
              border-color: var(--color-secondary-light-gray);
              box-sizing: border-box;
              border-radius: 8px;
              background: var(--color-secondary-extra-light-gray);
              padding: 14px 20px;
              font-size: 14px;
              font-weight: 400;
              color: var(--color-primary-black);
              line-height: 22px;
              margin-bottom: 10px;
              position: relative;
              display: block;
              width: 100%;
            }
            textarea {
              min-height: 150px;
            }
          }
        }
      }
    }

    .step-buttons {
      button {
        &:first-child {
          margin-right: 15px;
        }
      }
    }

    @media screen and (max-width: 900px) {
      padding: 40px 10px;
    }
    @media screen and (max-width: 767px) {
      box-shadow: none;
    }
  }
}
