@import 'src/styles/utilities/mixins';

.valueWrapper {
  display: flex;
  line-height: 24px;
  letter-spacing: 0.2px;
  flex: 1 1 50%;
  padding-right: 30px;
  margin-bottom: 10px;

  @include tablet-min() {
    flex: 1 1 100%;
    align-items: center;
  }

  &:nth-last-of-type(-n + 2) {
    margin-bottom: 0;

    @include tablet-min() {
      margin-bottom: 10px;
    }
  }

  .valueLabel {
    flex: 0 0 40%;
    font-weight: 500;
    padding-right: 20px;

    @include tablet-min() {
      flex: 0 0 60%;
    }
  }

  .valueText {
    font-weight: 400;
    color: var(--color-secondary-dark-gray);
  }

  &.fullWidth {
    flex: 1 1 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .valueLabel {
      flex: 0 0 15%;

      @include tablet-min() {
        flex: 0 0 50%;
      }
    }

    &.longLabel {
      .valueLabel {
        flex: 0 0 25%;
      }
    }

    &.label35 {
      .valueLabel {
        flex: 0 0 35%;
      }
    }
  }
}
