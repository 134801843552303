@import 'src/styles/utilities/mixins';

.form {
  .double-form-group {
    display: flex;

    .splitter {
      margin: 0 60px 20px;
      width: 1px;
      background-color: var(--color-secondary-light-gray);
    }

    .form-group {
      padding-top: 10px;

      .label {
        font-size: 1.13rem;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }

    @include tablet-min() {
      flex-direction: column;

      .splitter {
        display: none;
      }
    }
  }

  .form-group {
    display: inline-grid;
    width: 100%;
    position: relative;

    .label {
      display: block;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 8px;
      font-weight: 600;
      color: var(--color-primary-black);
    }

    textarea {
      resize: none;
    }

    .validation-error {
      display: block;
      margin-top: -17px;
      font-size: 13px;
      font-weight: 400;
      color: var(--color-validation-error);
      font-feature-settings: 'pnum' on, 'lnum' on;

      & ~ .validation-error {
        margin: 0;
      }
    }

    .input-wrapper {
      position: relative;
      width: 100%;
      margin-bottom: 22px;

      &.mb-0 {
        margin-bottom: 0;
      }

      @include tablet-min() {
        margin-bottom: 18px;
      }
    }

    .select-input {
      margin-bottom: 22px;

      @include tablet-min() {
        margin-bottom: 18px;
      }
    }

    .input {
      width: 100%;
      border: 1px solid;
      border-color: var(--color-secondary-light-gray);
      box-sizing: border-box;
      border-radius: 8px;
      background: var(--color-secondary-extra-light-gray);
      padding: 14px 16px;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-primary-black);
      line-height: 22px;
      //margin-bottom: 22px;
      display: block;

      //@include tablet-min() {
      //  margin-bottom: 18px;
      //}

      &::placeholder {
        font-size: 14px;
        color: var(--color-secondary-dark-gray);
      }

      &.field-invalid {
        border-color: var(--color-validation-error);

        & ~ .validation-error {
          display: block;
        }
      }

      & ~ .eye-icon {
        position: absolute;
        right: 16px;
        top: 55%;
        transform: translateY(-50%);
        cursor: pointer;

        &:before {
          content: url('../../assets/images/icons/eye-invisible.svg');
          width: 20px;
          height: 20px;
        }

        &.show {
          &:before {
            content: url('../../assets/images/icons/eye-visible.svg');
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 670px;

    .form-group {
      display: flex;
      flex-wrap: wrap;

      .label {
        font-weight: 600;
        font-size: 20px;
        line-height: 50px;
        flex: 0 0 35%;

        &.align-center {
          align-self: center;
        }
      }

      .input-wrapper {
        flex-basis: 65%;
      }

      .validation-error {
        position: absolute;
        top: 100%;
        left: 35%;
        margin-top: -22px;
      }

      .radio-buttons-group {
        flex-direction: row;
        width: 100%;

        &__label {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          flex: 0 0 35%;
        }

        .radio-button {
          flex: 1 1 50%;
        }
      }

      &.number {
        .label {
          font-weight: 500;
          font-size: 16px;
        }

        .input {
          max-width: 110px;
          margin-bottom: 0;
        }
      }
    }

    @include tablet-min() {
      .form-group {
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;

        .label {
          font-size: 16px;
          margin-bottom: 0;

          &.mobile-hidden {
            display: none;
          }

          &.align-center {
            flex: unset;
            align-self: self-start;
          }
        }

        .validation-error {
          position: absolute;
          top: 100%;
          left: 0;
          margin-top: -18px;
        }

        .radio-buttons-group {
          flex-direction: column;

          &__label {
            font-size: 16px;
            margin-top: 15px;
          }

          .radio-button {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &.number {
          display: flex;
          flex-direction: row;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          .label {
            font-size: 14px;
            margin: 0;
          }

          .input {
            padding: 9px 20px;
          }
        }
      }
    }

    &.form-group-vertical {
      .form-group {
        flex-direction: column;
        align-items: flex-start;

        .label {
          margin-bottom: 15px;
        }
      }

      .radio-buttons-group {
        .radio-button {
          padding-bottom: 20px;

          label {
            font-size: 16px;
          }
        }
      }
    }
  }

  &.horizontal {
    display: flex;

    @include tablet-min() {
      flex-direction: column;
      align-items: center;
    }

    .form-group {
      &:not(:first-child) {
        &:before {
          content: unset !important;
        }
      }

      &:not(:last-child):before {
        content: '';
        position: absolute;
        top: 15%;
        right: 0;
        height: 40%;
        border-right: 1px solid var(--color-secondary-light-gray);
        transform: rotate(180deg);
        z-index: 99;
      }

      .input {
        border-radius: 0;
        border: none;
        margin: 0;

        @include tablet-min() {
          margin-bottom: 16px;
          border-radius: 8px;
        }
      }

      @include tablet-max() {
        &:first-child {
          .input {
            border-radius: 8px 0 0 8px;
          }
        }
      }
    }

    @include tablet-max() {
      button {
        border-radius: 0 8px 8px 0;
        line-height: 18px;
      }
    }
  }
}
