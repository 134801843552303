@import 'src/styles/utilities/mixins';

.header {
  flex-shrink: 0;
  background-color: var(--color-primary-white);
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.12));
  position: relative;
  z-index: 999;

  .searchForm {
    padding: 20px;
    background-color: var(--color-secondary-accent);
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0 24px 0;

    @include tablet-min() {
      padding: 18px 0;
      font-size: 18px;
      font-weight: 700;
    }

    .rightPart {
      display: flex;
      align-items: center;

      .mobileBurger {
        display: none;
        width: 28px;
        height: 28px;
        background: url('../../../assets/images/icons/burger.svg') center center;
        background-size: 28px 28px;

        @include tablet-min() {
          display: block;
        }
      }

      .mobileBurgerClose {
        display: none;
        width: 28px;
        height: 28px;
        background: url('../../../assets/images/icons/burger-close.svg') center center;
        background-size: 28px 28px;

        @include tablet-min() {
          display: block;
        }
      }

      .headerMenu {
        display: flex;
        align-items: center;
        z-index: 999;

        @include tablet-min() {
          display: none;
          position: absolute;
          top: 66px;
          left: 0;
          width: 100%;
          background-color: var(--color-primary-white);
          box-shadow: inset 4px 7px 15px 0 rgb(0 0 0 / 12%);
          -webkit-box-shadow: inset 4px 7px 15px 0 rgb(0 0 0 / 12%);

          &.opened {
            display: block;
          }
        }

        ul {
          display: flex;
          font-weight: 600;

          @include tablet-min() {
            flex-direction: column;
            text-align: center;
            padding: 25px 20px 0;
            font-size: 18px;
            font-weight: 700;
          }

          li {
            padding-right: 45px;

            @include tablet-min() {
              padding-right: 0;
              padding-bottom: 25px;
              width: 100%;

              &:last-child {
                border-bottom: 1px solid var(--color-secondary-light-gray);
              }
            }

            a.active {
              font-weight: 700;
              color: var(--color-primary-yellow);
              border-bottom: 2px solid var(--color-primary-yellow);
              padding-bottom: 4px;
            }
          }
        }

        .signInButtonsWrapper {
          color: var(--color-secondary-accent);
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;

          @include tablet-min() {
            padding: 20px 0;
          }

          .signInButton {
            display: flex;
            align-items: center;

            &:before {
              content: url('../../../assets/images/icons/log-in.svg');
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }

            @include tablet-min() {
              &:before {
                content: unset;
              }
            }
          }

          .signUpButton {
            display: none;

            @include tablet-min() {
              display: block;
            }
          }

          .userInfo {
            font-size: 1rem;
            font-weight: normal;
            cursor: pointer;
            position: relative;

            &.userInfoShowMenu {
              .userMenu {
                display: block;
              }
            }

            .userInfoWrapper {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            @include tablet-min() {
              &.userInfoShowMenu {
                .userInfoWrapper:before {
                  margin-bottom: 0;
                  transform: rotate(0);
                }
              }

              .userInfoWrapper {
                flex-direction: row-reverse;

                .avatar {
                  margin-right: 12px;
                  margin-left: 0;
                }

                &:before {
                  content: url('../../../assets/images/icons/select-arrow.svg');
                  margin-left: 8px;
                  width: 20px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transform: rotate(180deg);
                  margin-bottom: -5px;
                }
              }
            }

            .userName {
              color: var(--color-secondary-dark-gray);
            }

            .avatar {
              border: 2px solid;
              border-radius: 20px;
              margin-left: 12px;

              img {
                border-radius: 20px;
              }
            }

            .userMenu {
              position: absolute;
              display: none;
              background: var(--color-secondary-extra-light-gray);
              border: 1px solid var(--color-secondary-light-gray);
              box-shadow: 0 2px 20px rgb(0 0 0 / 15%);
              border-radius: 8px;
              color: var(--color-primary-black);
              top: 52px;
              right: 0;
              line-height: 22px;
              width: 180px;

              @include tablet-min() {
                position: relative;
                top: 0;
                border: none;
                box-shadow: none;
                background: unset;
                width: 100%;
                padding-top: 16px;
              }

              .userMenuItems {
                flex-direction: column;

                @include tablet-min() {
                  padding: 0;
                }

                li {
                  font-weight: 400;
                  padding: 0;

                  a {
                    padding: 8px 16px;
                    display: block;
                  }

                  &:first-child {
                    border-radius: 8px 8px 0 0;
                  }

                  &:last-child {
                    border-radius: 0 0 8px 8px;
                    padding: 8px 16px;
                  }

                  &:hover {
                    background-color: var(--color-primary-yellow);
                  }

                  @include tablet-min() {
                    font-weight: 500;
                    font-size: 18px;
                    padding: 0 0 25px;

                    &:first-child,
                    &:last-child {
                      border-radius: 0;
                      border: none;
                    }

                    &:last-child {
                      padding-bottom: 0;
                    }

                    &:hover {
                      background-color: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
