.content {
  width: 100%;
  padding-top: 30px;

  .filters {
    width: 250px;

    .form {
      width: 100%;
    }
  }
}
