@import 'src/styles/utilities/mixins';

.checkbox-group {
  display: flex;
  padding-bottom: 9px;

  .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &--label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      &:before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid var(--color-secondary-light-gray);
        border-radius: 4px;
        margin-right: 12px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;

        @include mobile-max() {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.checked {
      + .checkbox--label {
        &:before {
          content: url('../../assets/images/icons/selected.svg');
          border: 1px solid var(--color-primary-yellow);
          padding: 3px;

          @include mobile-max() {
            padding: 1px;
          }
        }
      }
    }
  }
}
