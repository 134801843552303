@import 'src/styles/utilities/mixins';

.contact-us-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .contacts {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 15px;
    vertical-align: center;

    @include tablet-min {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .phone {
      margin-right: 46px;

      @include tablet-min {
        margin: 0 0 10px 0;
      }
    }

    .phone,
    .mail {
      position: relative;
    }

    .phone:before {
      position: absolute;
      left: -24px;
      content: url('../../assets/images/icons/phone16x16.svg');
    }

    .mail:before {
      position: absolute;
      left: -24px;
      content: url('../../assets/images/icons/email16x16.svg');
    }
  }

  .subtext {
    margin-bottom: 30px;
    @include tablet-min {
      margin-bottom: 15px;
    }
  }

  .textarea {
    height: 150px;
    resize: none;
    @include tablet-min {
      height: 120px;
    }
  }
}
