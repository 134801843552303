@import 'src/styles/utilities/mixins';

.searchFormWrapper {
  width: 100%;

  .form {
    max-width: 580px;
    margin: 0 auto;
  }

  @include tablet-min() {
    margin: 0;

    .form {
      display: none;
      padding-top: 20px;
    }

    &.opened {
      .form {
        display: flex;
      }
    }
  }
}
