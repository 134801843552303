@import 'src/styles/utilities/mixins';

.card {
  background: var(--color-primary-white);
  border: 1px solid var(--color-secondary-light-gray);
  border-radius: 16px;
  flex: 0 0 calc(33% - 15px);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  @include tablet-min() {
    flex-basis: calc(50% - 15px);
  }

  @include mobile-max() {
    flex-basis: 100%;
  }

  .cardImage {
    width: 100%;
    min-height: 250px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }

  .cardWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    button {
      margin-top: auto;
    }

    .companyInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .companyInfoImage {
        img {
          height: 30px;
          max-width: 150px;
          object-fit: contain;
        }
      }
    }

    .title {
      font-size: 20px;
      color: #333333;
      font-weight: 700;
      line-height: 30px;

      @include tablet-min() {
        font-size: 18px;
        line-height: 28px;
      }
    }

    .description {
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }

    &--card {
      padding: 20px;

      @include tablet-min() {
        flex-basis: 48%;
      }

      .description {
        padding: 15px 0;

        @include tablet-min() {
          padding: 10px 0 15px 0;
        }
      }
    }

    &--company {
      padding: 30px;

      @include tablet-min() {
        padding: 25px;
      }

      .description {
        padding: 6px 0 20px 0;
      }

      .title {
        font-size: 1.125rem;
        padding-top: 15px;

        @include tablet-min() {
          padding-top: 10px;
        }
      }
      .ratedButtons {
        display: flex;

        button {
          margin-right: 10px;
        }
      }
    }
  }
}
