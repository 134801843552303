@import 'src/styles/utilities/mixins';

.cardTitleContent {
  flex: 1 1 100%;

  &.withClear {
    padding-bottom: 25px;

    @include tablet-min() {
      padding-bottom: 20px;
    }

    .cardTitleWrapper {
      display: flex;

      .cardTitle {
        flex: unset;
        margin-right: 30px;
        margin-bottom: 10px;
      }
    }
  }

  .cardTitleWrapper {
    .cardTitle {
      flex: 1 1 100%;
      margin-bottom: 20px;
    }

    &.withLabel {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      .cardTitle {
        flex: unset;

        @include mobile-max() {
          margin-bottom: 10px;
        }
      }

      .reviewLabelWrapper {
        margin-top: -20px;
        margin-left: 20px;

        @include small-desktop-min() {
          margin-top: 0;
          margin-bottom: 20px;
        }

        @include mobile-max() {
          margin-left: 0;
        }
      }
    }
  }

  .cardSubText {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-secondary-dark-gray);
  }

  .cardText {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
  }
}
