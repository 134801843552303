@import 'src/styles/utilities/mixins';

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .Layout {
    flex: 1 0 auto;

    .companyPageBanner {
      width: 100%;
      background: url('./assets/images/company-page-banner.jpg') center center;
      height: 100vh;
      max-height: 550px;
      background-size: cover;
      position: relative;

      @include tablet-min() {
        max-height: 350px;
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(15, 27, 54, 0.23);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    .blogPageBanner {
      width: 100%;
      min-height: 300px;
      max-height: 550px;

      img {
        object-fit: cover;
        width: 100%;
        min-height: 300px;
        max-height: 550px;
        background: rgba(15, 27, 54, 0.23);
        margin-right: 30px;
      }
    }
  }
}
