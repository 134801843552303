@import 'src/styles/utilities/mixins';

.bgImage {
  width: 100%;
  background: url('../../assets/images/employer-bg.jpg') center center;
  height: calc(100vh - 80px);
  background-size: cover;
  color: var(--color-primary-white);
  position: relative;
  z-index: 1;

  @include tablet-min() {
    height: calc(100vh - 66px);
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(15, 27, 54, 0.23);
  }

  .employerWrapper {
    z-index: 2;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    letter-spacing: 0.2px;

    @include tablet-min() {
      padding: 0 20px;
    }

    .employerWrapperContent {
      background: rgba(21, 22, 24, 0.65);
      border-radius: 4px;
      padding: 50px;
      word-break: break-word;
      text-align: center;
      max-width: 690px;

      @include tablet-min() {
        padding: 40px 20px;
      }

      .title {
        padding: 0 30px;

        @include tablet-min() {
          padding: 0;
        }

        .titleColored {
          color: var(--color-primary-yellow);
        }
      }

      .subText {
        text-align: center;
        opacity: 0.8;
        padding: 15px 0 25px 0;
      }

      .contactsInfo {
        display: flex;
        justify-content: space-evenly;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;

        @include tablet-min() {
          flex-direction: column;
          align-items: center;
        }

        a {
          text-decoration: underline;
          text-underline-offset: 3px;
          display: flex;
          align-items: center;

          @include tablet-min() {
            &:first-child {
              margin-bottom: 20px;
            }
          }

          &:before {
            content: '';
            width: 19px;
            height: 20px;
            margin-right: 12px;
          }

          &.infoPhone:before {
            content: url('../../assets/images/icons/phone-receiver-yellow.svg');
          }

          &.infoEmail:before {
            content: url('../../assets/images/icons/email-yellow.svg');
          }
        }
      }
    }
  }
}
