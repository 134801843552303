.on-review-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    padding: 30px 0 15px;
  }

  span {
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}
