.reviewLabel {
  padding: 3px 10px;
  border-radius: 100px;

  &--approved {
    border: 1px solid var(--color-green-recommended);
    color: var(--color-green-recommended);
  }

  &--not-reviewed {
    border: 1px solid var(--color-secondary-dark-gray);
    color: var(--color-secondary-dark-gray);
  }

  &--rejected {
    border: 1px solid var(--color-red-rejected);
    color: var(--color-red-rejected);
  }
}
