@import 'src/styles/utilities/mixins';

.infoCardRating {
  display: flex;
  font-weight: 500;
  margin-bottom: 20px;
  flex: 0 0 100%;
  align-items: center;

  @include tablet-min() {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 25px;
  }

  &.editable {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 30px;

    .ratingWrapper {
      display: flex;
    }

    @include tablet-min() {
      font-size: 16px;
    }

    button {
      margin-left: 20px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .question {
    padding-right: 20px;

    @include tablet-min() {
      padding-right: 0;
      padding-bottom: 15px;
    }
  }
}
