.on-remove-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 16px;
}

.on-remove-section {
  width: 100%;
  align-items: flex-start;
  display: flex;
  margin-bottom: 12px;

  .on-remove-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-right: 20px;
  }

  .on-remove-value {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-secondary-dark-gray);
    line-height: 21px;
  }
}

.on-remove-buttons {
  :first-child {
    margin-right: 16px;
  }
  width: 100%;
  align-items: flex-start;
  margin-top: 28px;
}
