@import 'styles/main';

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
}

html,
body {
  font-family: 'Inter', sans-serif;
  font-size: $indent-font-size;
  line-height: 19px;
  color: var(--color-primary-black);

  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;

  font-smoothing: antialiased;

  @include tablet-min() {
    font-size: 14px;
  }
}

.show-modal {
  overflow: hidden;
}

button[disabled] {
  background-color: rgba(131, 131, 131, 0.8);
}

.section {
  max-width: 1140px;
  margin: 0 auto;

  @include small-desktop-min() {
    padding: 0 20px;
  }

  &__block {
    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.no-top-padding {
      padding-top: 0;
    }

    &.no-bottom-padding {
      padding-bottom: 0;
    }

    @include tablet-min() {
      padding-top: 80px;
      padding-bottom: 80px;

      &.no-top-padding {
        padding-top: 0;
      }

      &.no-bottom-padding {
        padding-bottom: 0;
      }
    }
  }

  &__block--blog {
    position: relative;
  }

  &.home-page {
    width: 100%;
    max-width: unset;
    background-color: var(--color-secondary-extra-light-gray);

    .section__block {
      padding-top: 60px;
      padding-bottom: 60px;
      max-width: 1140px;
      margin: 0 auto;
    }

    & ~ .home-page {
      .section__block {
        padding-top: 0;
      }
    }
  }
}

.flexWrapper {
  display: flex;
  flex-wrap: wrap;

  &--vertical {
    flex-direction: column;
  }

  &--center {
    justify-content: center;
  }

  &--align-center {
    align-items: center;
  }

  &.space-between {
    width: 100%;
    justify-content: space-between;
  }
}

.logo {
  width: 128px;
  height: 38px;

  @include tablet-min() {
    width: 100px;
    height: 30px;
  }

  &.footer {
    width: 150px;
    height: 45px;
  }
}

.filters {
  padding-bottom: 8px;
  align-self: flex-start;

  @include tablet-min() {
    width: 100%;
  }
}

.pt-0 {
  padding-top: 0 !important;
}

.uploaded-logo {
  width: 150px;
  margin-left: auto;
  margin-top: -75px;
  margin-bottom: 15px;
}
