.pagination {
  display: flex;
  align-items: center;
  padding-top: 30px;
  justify-content: center;

  .page {
    display: block;
    position: relative;
    border: 1px solid var(--color-secondary-accent);
    border-radius: 50%;
    margin: 0 8px;
    color: var(--color-secondary-accent);
    font-weight: 600;
    cursor: pointer;
    padding: 8px 14px;
    width: 35px;
    height: 35px;

    a {
      position: absolute;
      left: -1px;
      top: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.selected {
      background: var(--color-secondary-accent);
      color: var(--color-primary-white);
    }
  }

  .next,
  .previous {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    a {
      width: 20px;
      height: 20px;
      z-index: 999;
      display: block;
    }

    &.disabled {
      visibility: hidden;
    }

    &:before {
      width: 14px;
      height: 14px;
      position: absolute;
    }
  }

  .next {
    &:before {
      content: url('../../assets/images/icons/pagination-arrow.svg');
      right: 0;
    }
  }

  .previous {
    &:before {
      content: url('../../assets/images/icons/pagination-arrow.svg');
      transform: rotate(180deg);
    }
  }
}
