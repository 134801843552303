@import 'src/styles/utilities/mixins';

.aboutUsWrapper {
  background-color: var(--color-primary-white);
  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.08));

  .topSection {
    display: flex;

    @include tablet-min() {
      flex-direction: column;
    }
  }

  .aboutUsBlock {
    padding: 45px;
    flex: 1;

    @include tablet-min() {
      padding: 30px 20px;
    }

    .title {
      line-height: 40px;
    }

    .description {
      line-height: 24px;
      padding: 15px 0;
      font-weight: 400;

      @include tablet-min() {
        padding: 10px 0 0 0;
      }
    }

    &.aboutUsBlockImage {
      width: 100%;
      background: url('../../assets/images/about-us-banner.jpg') center center;
      height: 100%;
      background-size: cover;
      color: var(--color-primary-white);
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgba(23, 23, 23, 0.5);
      }
    }

    &.aboutUsBlockYellow {
      background-color: var(--color-primary-yellow);
    }
  }
}
