@import 'src/styles/utilities/mixins';

.banner {
  width: 100%;
  background: url('../../../../assets/images/main-banner-image.jpg') center center;
  height: 100vh;
  max-height: 700px;
  background-size: cover;
  color: var(--color-primary-white);

  .mainWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 50px;
    text-align: center;

    @include tablet-min() {
      margin: 0 20px;
    }

    .mainArea {
      background: rgba(21, 22, 24, 0.5);
      border-radius: 4px;
      padding: 50px;
      text-align: center;

      @include tablet-min() {
        padding: 30px 20px;
      }

      .title {
        padding: 0 10px 15px;
        max-width: 590px;

        @include tablet-min() {
          padding: 0;
        }

        h1 {
          display: block;
          padding-bottom: 15px;
          text-align: center;
        }
      }

      .subTitle {
        display: block;
        padding-bottom: 30px;

        @include tablet-min() {
          padding-bottom: 20px;
        }
      }
    }

    h2 {
      padding: 50px 0 30px 0;

      @include tablet-min() {
        padding: 30px 0 15px 0;
      }
    }
  }
}
