.infoBanner {
  width: 100%;
  position: relative;
  border: 1px solid var(--color-secondary-accent);
  background-color: rgba(196, 33, 74, 0.1);
  border-radius: 16px;
  display: flex;
  justify-content: center;

  &.yellow {
    border-color: var(--color-primary-yellow);
    background-color: rgba(255, 164, 37, 0.1);;
  }

  .closeBanner {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;

    &:before {
      content: url("../../../assets/images/icons/close.svg");
      width: 16px;
      height: 16px;
    }
  }

  .content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      padding: 15px 0 30px;
      font-weight: 400;
    }
  }
}