@import 'src/styles/utilities/mixins';

.jobTrend {
  padding: 10px 20px;
  border: 1px solid var(--color-secondary-dark-gray);
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  margin: 0 15px 15px 0;

  @include tablet-min() {
    margin: 0 8px 8px 0;
  }

  &.clickable {
    padding: 10px 30px;
    cursor: pointer;
  }

  &.active {
    border: 2px solid var(--color-primary-yellow);
  }
}
