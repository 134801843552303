@import 'src/styles/utilities/mixins';

.mainInfoWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include tablet-min() {
    flex-direction: column;
    position: relative;

    button {
      position: absolute;
      right: 0;
      top: 10px;
    }
  }

  .infoContent {
    display: flex;
    width: 100%;

    @include tablet-min() {
      flex-direction: column;
      position: relative;
    }

    .companyImage {
      border-radius: 16px;
      margin-right: 40px;
      max-width: 250px;
      width: 100%;
      object-fit: contain;

      @include tablet-min() {
        max-width: 120px;
      }
    }

    .mainInfo {
      @include tablet-min() {
        padding-top: 20px;
      }

      .noRating {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        line-height: 24px;

        span:first-child {
          font-weight: 400;
          padding-bottom: 15px;
        }

        .beFirst {
          color: var(--color-secondary-accent);
          font-weight: 600;
        }
      }

      .mainInfoData {
        .companyName {
          line-height: 54px;
          padding-bottom: 5px;

          @include tablet-min() {
            line-height: 42px;
          }
        }

        .companyLocation {
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 27px;
          letter-spacing: 0.2px;
          color: var(--color-secondary-dark-gray);
        }
      }

      .recommendation {
        display: flex;
        align-items: center;
        font-weight: 700;
        letter-spacing: 0.2px;
        margin: 15px 0;

        .recommendationBlock {
          margin-right: 16px;
          border-radius: 100px;
          padding: 6px 20px 6px 45px;
          font-size: 1.125rem;
          line-height: 27px;
          color: var(--color-primary-white);
          display: flex;
          position: relative;

          &:before {
            width: 24px;
            position: absolute;
            height: 24px;
            left: 10px;
            top: 7px;
          }
        }
        .good {
          background-color: var(--color-green-recommended);

          &:before {
            content: url('../../../../../../assets/images/icons/smile.svg');
          }
        }
        .failed {
          background-color: var(--color-validation-error);

          &:before {
            content: url('../../../../../../assets/images/icons/sad_smile.svg');
          }
        }

        .recommendationCount {
          font-size: 1rem;
          line-height: 24px;
          text-decoration: underline;
          color: var(--color-primary-yellow);
        }
      }

      .recommenderToFriends {
        color: var(--color-green-recommended-to-friends);
        font-weight: 700;
        font-size: 1.125rem;
        display: flex;
        align-items: center;

        .recommenderToFriendsStatus {
          width: 34px;
          height: 34px;
          padding: 6px;
          border-radius: 50%;
          display: block;
          margin-right: 10px;

          &.like {
            background-color: var(--color-green-recommended-to-friends);

            &:before {
              content: url('../../../../../../assets/images/icons/like.svg');
            }
          }
        }
      }
    }
  }
}
