@import 'src/styles/utilities/mixins';

.pageInfoCardWrapper {
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 35px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &.blured {
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 99999999;
      background-color: Rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(5px);
    }
  }

  @include tablet-min() {
    box-shadow: none;
    padding: 20px 0;
  }

  &:first-child {
    margin: 60px 0 20px 0;

    @include tablet-min() {
      margin: 20px 0 0 0;
    }

    &.no-margin {
      margin: 0;
    }
  }

  + .pageInfoCardWrapper {
    margin-bottom: 20px;

    @include tablet-min() {
      margin-bottom: 0;
    }
  }

  &.center {
    justify-content: center;
  }

  &.no-margin-top {
    margin-top: 0 !important;
  }
}
