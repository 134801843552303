@import 'src/styles/utilities/mixins';

.stepperWrapper {
  margin-bottom: 60px;

  @include tablet-min() {
    margin-bottom: 20px;
  }

  .stepList {
    display: flex;
    justify-content: center;
    li {
      width: 150px;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          background: var(--color-secondary-dark-gray);
          height: 2px;
          width: 80px;
          left: 75%;
          top: calc(30% - 2px);

          @include tablet-min() {
            left: 80%;
            width: 40%;
            top: calc(50% - 2px);
          }
        }
      }
      span {
        width: 35px;
        height: 35px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        border-radius: 100%;
        border: 1px solid var(--color-secondary-dark-gray);
        background-color: transparent;
        color: var(--color-secondary-dark-gray);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @include tablet-min() {
          width: 25px;
          height: 25px;
          font-size: 12px;
        }
      }
      h6 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: var(--color-secondary-dark-gray);
        padding: 0 1.5rem;
        margin-top: 10px;

        @include tablet-min() {
          display: none;
        }
      }
      &.active,
      &.completed {
        span {
          color: var(--color-primary-white);
          background-color: var(--color-secondary-accent);
          border-color: var(--color-secondary-accent);
        }
        a:after {
          background-color: var(--color-secondary-accent);
        }
        h6 {
          color: var(--color-secondary-accent);
          font-weight: 700;
        }
      }
      &.active {
        h6 {
          display: block;

          @include tablet-min() {
            padding: 0;
          }
        }

        @include tablet-min() {
          a:after {
            top: calc(25% - 2px);
          }
        }
      }
      &.completed {
        span:before {
          content: url('../../../assets/images/icons/stepper-tick.svg');
          z-index: 2;
          position: absolute;
          background-color: var(--color-secondary-accent);
          border-radius: 50%;
          top: 9px;

          @include tablet-min() {
            content: url('../../../assets/images/icons/stepper-tick-mobile.svg');
            width: 14px;
            height: 14px;
            top: 4px;
            left: 5px;
          }
        }
      }
      &:last-child {
        a:after {
          width: 0;
        }
      }
    }
  }
}
