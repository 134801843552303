.userProfileContainer {
  padding: 30px 0px;
  .card {
    background: #ffffff;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 25px 35px;
    margin-bottom: 20px;
  }
  .button {
    border: 1px solid #c4214a;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #c4214a;
    padding: 12px;
  }
  .greyText {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #838383;
    padding: 15px 0px;
  }
  .subtTtle {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #171717;
  }
  .profileDetails {
    display: flex;
    .avatar {
      width: 54px;
      height: 54px;
      border: 1px solid #c4214a;
      border-radius: 50%;
      margin-right: 20px;
    }
    .nameSection {
      display: flex;
      align-items: center;
    }
    .userName {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #171717;
      margin-right: 15px;
    }
    .changeNickName {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-decoration: underline;
      color: #ffa425;
      margin-left: 1px;
      cursor: pointer;
    }
  }
}
.title {
  margin-bottom: 20px;
}

.form {
  width: 100%;
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 8px;
  }
}

.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadingStatus {
  pointer-events: none;
}
