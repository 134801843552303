@import 'src/styles/utilities/mixins';

h1,
h2,
h3 {
  font-weight: bold;
}

h1 {
  font-size: 2rem;
  line-height: 44px;

  @include tablet-min() {
    line-height: 42px;
  }
}

h2 {
  font-size: 1.5rem;
  line-height: 29px;
}

h3 {
  font-size: 1.13rem;
  line-height: 22px;
}
