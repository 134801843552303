.jobPlacementInfoWrapper {
  display: flex;
  flex-direction: column;

  .cvWrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;

    button {
      margin-right: 15px;
    }
  }

  .uploadAnother {
    flex: 1 1 100%;
    margin-top: 20px;
  }

  .jobPlacementSectionTitleWrapper {
    flex: 1 1 100%;
    display: flex;

    &:not(:first-child) {
      margin-top: 40px;
    }

    span {
      font-size: 18px;
      color: var(--color-secondary-accent);
      font-weight: 700;
    }
  }

  .sections {
    display: flex;
    flex-direction: column;
    width: 100%;

    .section {
      flex: 1 1 100%;
      border-bottom: 1px solid var(--color-secondary-light-gray);
      padding: 25px 0;

      &.withTrend {
        padding-bottom: 15px;
      }

      &:last-child {
        border-bottom: none;
      }

      .sectionValue {
        font-weight: 400;
        font-size: 16px;
        color: var(--color-secondary-dark-gray);

        & + .sectionValue {
          margin-left: 10px;
        }
      }
    }
  }
}